import {
  Mediator,
  ModalWindow
} from 'application/src/js/util.js';

export default class EventNotificationBanner extends Mediator {
  constructor(args) {
    super(args);
    this.translations = args.translations;
    this.element = document.querySelector('#notification-banner');
    this.contentContainer = this.element.querySelector('.modal-body');
    this.modal = new ModalWindow(this.element);
    this.notification = null;
  }

  addEvents() {
    [...this.contentContainer.querySelectorAll('a')].forEach(t => {
      t.addEventListener('click', () => {
        if (this.notification && this.notification.click === 0) {
          this.publish('action', this.notification.user_notification_id);
        }
      });
    });
  }

  show(notification) {
    this.notification = notification;
    this.render();
    this.modal.open();
  }

  render() {
    if (!this.notification) return;

    this.contentContainer.innerHTML = '';

    const modalContent = this.element.querySelector('.modal-content');
    modalContent.style.backgroundColor = 'transparent';

    if (this.notification.image_url) {
      const imageContainer = document.createElement('div');
      imageContainer.classList.add('image-container');

      if (this.notification.link) {
        imageContainer.innerHTML = `
          <a href="${this.notification.link}" target="_blank">
            <img src="${this.notification.image_url}"/>
          </a>
        `;
      } else {
        imageContainer.innerHTML = `<img src="${this.notification.image_url}" />`;
      }

      const closeButton = this.element.querySelector('.close-banner');
      imageContainer.appendChild(closeButton);

      this.contentContainer.appendChild(imageContainer);
    }
    modalContent.classList.add('fade-in');
    this.addEvents();
  }
}
